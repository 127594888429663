import React, { memo, useEffect, useMemo } from 'react';
import { bool, shape, string } from 'prop-types';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { Metadata } from '@thd-olt-component-react/metadata';
import { getContentBreadCrumbLabels } from '../utils/breadCrumbUtils';
import { getAnalyticsPageType } from '../utils/getAnalyticsPageType';
import { getLayoutTemplate } from '../utils/getLayoutTemplate';
import { renderRobotTag } from '../../contentful/utils/utilities';

export const ContentPage = memo(({
  customerType, data, pageId, storeId, pathWithQueryParams, thdSEOBotDetection, isPreviewLink
}) => {

  const layouts = data?.layouts;
  const crumbs = layouts?.breadcrumbs?.breadcrumbItem || [];
  const metadata = layouts?.seo || {};

  useEffect(() => {
    if (data) {
      const breadCrumbLabels = getContentBreadCrumbLabels(crumbs);
      const pageType = getAnalyticsPageType(pathWithQueryParams);
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('landing-page.page-load', { breadCrumbLabels, pageId, pageType });
    }
  }, []);

  const pageTypeComponents = useMemo(() => {
    const { linkData, scriptData, ...metadataMinusLinkDataAndScriptData } = metadata;
    return ({
      landingPageBreadcrumbs: <Breadcrumbs staticCrumbs={crumbs} useStencilDesign />,
      landingPageMetadata: <Metadata data={{ ...metadataMinusLinkDataAndScriptData }}>{renderRobotTag(metadata)}</Metadata>
    });
  }, [data]);

  let Template = getLayoutTemplate(layouts);
  const { canonicalURL } = metadata;
  const filterBreadcrumbs = crumbs?.map(({ url, label }) => ({ browseUrl: url, label }));

  return (
    <>
      <Template
        canonicalURL={canonicalURL}
        customerType={customerType}
        data={data}
        filterBreadcrumbs={filterBreadcrumbs}
        pageId={pageId}
        pageTypeComponents={pageTypeComponents}
        storeId={storeId}
        thdSEOBotDetection={thdSEOBotDetection}
        isPreviewLink={isPreviewLink}
      />
    </>
  );
});

ContentPage.displayName = 'ContentPage';

ContentPage.propTypes = {
  customerType: string.isRequired,
  data: shape({}).isRequired,
  pageId: string.isRequired,
  storeId: string.isRequired,
  pathWithQueryParams: string.isRequired,
  thdSEOBotDetection: bool,
  isPreviewLink: bool,
};

ContentPage.defaultProps = {
  thdSEOBotDetection: false,
  isPreviewLink: false
};
