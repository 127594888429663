import React, { memo, useEffect, useContext, useMemo } from 'react';
import { useDataModel, QueryContext } from '@thd-nucleus/data-sources';
import { bool, shape, string } from 'prop-types';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { CategoryMetadata } from '@thd-olt-component-react/metadata';
import { getCategoryBreadCrumbLabels } from '../utils/breadCrumbUtils';
import { getAnalyticsPageType } from '../utils/getAnalyticsPageType';
import { getLayoutTemplate } from '../utils/getLayoutTemplate';

export const CategoryPage = memo(({
  customerType, data, pageId, storeId, pathWithQueryParams, thdSEOBotDetection, isPreviewLink
}) => {

  const { data: searchModelData, loading: searchModelLoading, error: searchModelError } = useDataModel('searchModel', {
    variables: {
      navParam: pageId,
      keyword: null
    },
  });

  const { isClientResolved } = useContext(QueryContext);
  const resolved = isClientResolved({ queryName: 'searchModel' });
  const layouts = data?.layouts;

  useEffect(() => {
    if (data && searchModelData && !searchModelLoading) {
      const breadCrumbLabels = getCategoryBreadCrumbLabels(searchModelData?.searchModel);
      const pageType = getAnalyticsPageType(pathWithQueryParams);
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('landing-page.page-load', { breadCrumbLabels, pageId, pageType });
    }
  }, [resolved]);

  const pageTypeComponents = useMemo(() => {
    return ({
      landingPageBreadcrumbs: <Breadcrumbs navParam={pageId} useStencilDesign />,
      landingPageMetadata: <CategoryMetadata navParam={pageId} pathWithQueryParams={pathWithQueryParams} />
    });
  }, [data]);

  let Template = getLayoutTemplate(layouts);
  const { canonicalUrl } = searchModelData?.searchModel?.metadata || {};
  const isBreadCrumb = searchModelData?.searchModel?.taxonomy?.breadCrumbs;
  const filterBreadcrumbs = isBreadCrumb?.map(({ browseUrl, label }) => ({ browseUrl, label }));

  return (
    <>
      <Template
        canonicalURL={canonicalUrl}
        customerType={customerType}
        data={data}
        filterBreadcrumbs={filterBreadcrumbs}
        pageId={pageId}
        pageTypeComponents={pageTypeComponents}
        storeId={storeId}
        thdSEOBotDetection={thdSEOBotDetection}
        isPreviewLink={isPreviewLink}
      />
    </>
  );
});

CategoryPage.displayName = 'CategoryPage';

CategoryPage.propTypes = {
  customerType: string.isRequired,
  data: shape({}).isRequired,
  pageId: string.isRequired,
  storeId: string.isRequired,
  pathWithQueryParams: string.isRequired,
  thdSEOBotDetection: bool,
  isPreviewLink: bool
};

CategoryPage.defaultProps = {
  thdSEOBotDetection: false,
  isPreviewLink: false
};
