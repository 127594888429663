export const getCategoryBreadCrumbLabels = ({ taxonomy } = {}) => {
  return (taxonomy?.breadCrumbs || []).reduce((filtered, breadCrumb) => {
    if (
      ['category', 'the home depot events', 'brand'].includes(breadCrumb?.dimensionName?.toLowerCase())
    ) {
      filtered.push(breadCrumb.label);
    }
    return filtered;
  }, []);
};

export const getContentBreadCrumbLabels = (crumbs = []) => {
  return crumbs.map((crumb) => crumb?.label);
};