import React, { memo } from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { LandingContainer } from './containers/LandingContainer';

export const LandingPage = memo(() => {
  const storeId = useStoreId();
  const { isCustomerIdentified } = useThdCustomer() || {};

  const defaultVariables = {
    keyword: null,
    slug: 'move',
    storeId,
    isBrandPricingPolicyCompliant: !!isCustomerIdentified
  };

  return (
    <QueryProvider cacheKey="landing-page" defaultVariables={defaultVariables}>
      <LandingContainer storeId={storeId} />
    </QueryProvider>
  );
});
