import React from 'react';
import { bool, shape, string, arrayOf } from 'prop-types';
import { EducationalLayoutRenderer } from './EducationalLayoutRenderer';

/**
 * Main job for this component is to fetch data. The useDataModel call will cause this component to rerender which is
 * why the rendering of the components is split into EducationalLayoutRenderer so that they don't unnecessarily
 * rerender. Rerendering components can result in extra API calls and processing, we do not want that.
 */
export const EducationalLayout = ({
  canonicalURL, data, customerType, filterBreadcrumbs, pageId, storeId, pageTypeComponents, thdSEOBotDetection, isPreviewLink
}) => {

  let search = '';

  if (typeof window !== 'undefined') {
    search = window.location?.search;
  }

  return (
    <EducationalLayoutRenderer
      canonicalURL={canonicalURL}
      data={data}
      filterBreadcrumbs={filterBreadcrumbs}
      pageId={pageId}
      customerType={customerType}
      storeId={storeId}
      pageTypeComponents={pageTypeComponents}
      search={search}
      thdSEOBotDetection={thdSEOBotDetection}
      isPreviewLink={isPreviewLink}
    />
  );
};

EducationalLayout.displayName = 'EducationalLayout';

EducationalLayout.propTypes = {
  canonicalURL: string,
  customerType: string.isRequired,
  data: shape({}).isRequired,
  filterBreadcrumbs: arrayOf(shape(
    {
      browseUrl: string,
      label: string,
    },
  )),
  pageTypeComponents: shape({}).isRequired,
  pageId: string.isRequired,
  storeId: string.isRequired,
  thdSEOBotDetection: bool,
  isPreviewLink: bool
};

EducationalLayout.defaultProps = {
  canonicalURL: null,
  filterBreadcrumbs: null,
  thdSEOBotDetection: false,
  isPreviewLink: false
};
