import React, { memo } from 'react';
import { string } from 'prop-types';
import { LandingPageRedirector, LandingPageSearchRedirector } from '@thd-nucleus/app-render';

export const Redirector = memo(({ customerType, layoutType, pageId }) => {
  if (layoutType === 'Category') {
    return <LandingPageSearchRedirector navParam={pageId} />;
  }
  return <LandingPageRedirector customerType={customerType} slug={pageId} />;
});

Redirector.propTypes = {
  customerType: string,
  layoutType: string,
  pageId: string
};

Redirector.defaultProps = {
  customerType: '',
  layoutType: 'Content',
  pageId: ''
};
