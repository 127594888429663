import React from 'react';
import { arrayOf, shape, string as stringType } from 'prop-types';

export const MarketingQuestionAnswer = ({ qaSets }) => {
  return (
    <div data-component="MarketingQuestionAnswer">
      {qaSets.map((content, i) => (
        <dl key={i}>
          <dt dangerouslySetInnerHTML={{ __html: content.Question }} />
          <dd dangerouslySetInnerHTML={{ __html: content.Answer }} />
          <br />
        </dl>
      ))}
    </div>
  );
};

MarketingQuestionAnswer.displayName = 'MarketingQuestionAnswer';

MarketingQuestionAnswer.propTypes = {
  qaSets: arrayOf(shape({
    Question: stringType,
    Answer: stringType
  })).isRequired
};
