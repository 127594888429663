import React from 'react';
import {
  string,
  shape,
  bool,
  oneOfType,
  instanceOf,
  object
} from 'prop-types';
import {
  useHelmet, TitleTag, MetaTag, ScriptTag, LinkTag
} from '@thd-nucleus/thd-helmet';
import {
  arrayOf as arrayType,
  customType,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
  useDataModel,
  extend
} from '@thd-nucleus/data-sources';
import {
  EmtGeneratedContent
} from '@thd-olt-component-react/emt-generated-content';
import { useStoreId } from '@thd-nucleus/experience-context';
import {
  getTitleTag,
  getnValue,
  removeSpecialCharacters,
  parseCrumbs,
  processBreadcrumbs,
  getDescription
} from './metadata-helpers';
import { getBreadcrumbStructuredData } from './structured-data/getBreadcrumbStructuredData';
import { getBrowseSearchStructuredData } from './structured-data/getBrowseSearchStructuredData';

const browseHandler = ({ data, props = {}, queries }) => {

  if (!data?.searchModel) return null;
  const {
    searchReport,
    metadata,
    taxonomy,
    templates,
    products
  } = data?.searchModel;

  let parsedTemplates;
  const { pathWithQueryParams } = props;

  try {
    parsedTemplates = JSON.parse(templates?.[0]);
  } catch {
    parsedTemplates = templates?.[0];
  }

  const { keyword: searchReportKeyword } = searchReport || {};
  const nValue = getnValue(metadata?.canonicalUrl);
  const canonical = `https://www.homedepot.com${metadata?.canonicalUrl}`;
  const pageType = pathWithQueryParams.indexOf('/b/') > -1 ? 'b' : 's';
  const processedBreadcrumbs = parseCrumbs([...(taxonomy?.breadCrumbs || [])], searchReportKeyword);
  // eslint-disable-next-line camelcase
  const isContentPageOnly = parsedTemplates?.options?.content_type === 'categorypage';
  const [categories, refinements] = processBreadcrumbs(taxonomy?.breadCrumbs);
  let description = getDescription(pageType, categories, refinements, searchReportKeyword, true);
  let title = getTitleTag({
    pageType, categories, refinements, searchVal: searchReportKeyword, pathWithQueryParams
  });
  let type = 'Category';
  if (isContentPageOnly) {
    if (!(categories[0] === 'Appliances' && pageType === 'b')) {
      description = parsedTemplates?.options?.metaData?.description;
    }
    title = parsedTemplates?.options?.metaData?.title;
  }

  const isNoFollow = metadata?.canonicalUrl?.indexOf('NCNI-5') > -1
    || pathWithQueryParams.indexOf('NCNI-5') > -1
    || pathWithQueryParams.indexOf('Ns=P_Topseller_Sort%7C1') > -1
    || pathWithQueryParams.indexOf('Ns=P_Smartsort%7C1') > -1
    || pathWithQueryParams.indexOf('Ns=P_REP_PRC_MODE%7C0') > -1
    || pathWithQueryParams.indexOf('Ns=P_REP_PRC_MODE%7C1') > -1
    || pathWithQueryParams.indexOf('Ns=P_Top_Rated_Sort%7C1') > -1
    || pathWithQueryParams.indexOf('sortorder=') > -1
    || pathWithQueryParams.indexOf('sortby=') > -1
    || pathWithQueryParams.indexOf('Ntk-') > -1
    || pathWithQueryParams.indexOf('/b/Featured-Products') > -1
    || pathWithQueryParams.indexOf('Ntt-') > -1
    || pathWithQueryParams.indexOf('lowerBound=') > -1
    || pathWithQueryParams.indexOf('upperBound=') > -1;

  const tags = [];

  tags.push(new TitleTag(title));

  tags.push(new LinkTag({ rel: 'canonical', href: canonical, id: 'canonical' }));
  tags.push(new MetaTag({ name: 'description', content: description, id: 'description' }));
  tags.push(new MetaTag({ property: 'og:title', content: title, id: 'ogTitle' }));
  tags.push(new MetaTag({ property: 'og:url', content: canonical, id: 'ogUrl' }));
  tags.push(new MetaTag({ property: 'og:type', content: type, id: 'ogType' }));
  tags.push(new MetaTag({ property: 'og:description', content: description, id: 'ogDescription' }));

  tags.push(new MetaTag({ property: 'og:site_name', content: 'The Home Depot', id: 'ogSiteName' }));

  tags.push(new MetaTag({ property: 'fb:admins', content: '100005416960152', id: 'fbAdmins' }));

  if (isNoFollow) {
    tags.push(new MetaTag({ name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW', id: 'robots' }));
  } else {
    tags.push(new MetaTag({ name: 'robots', content: 'max-image-preview:large', id: 'robots' }));
  }

  if (processedBreadcrumbs && !!processedBreadcrumbs.length) {
    const formattedLabel = removeSpecialCharacters(processedBreadcrumbs[0].label).replace(/\s+/g, '-');
    tags.push(new LinkTag({
      rel: 'alternate',
      media: 'only screen and (max-width: 640px)',
      href: `android-app://com.thehomedepot/homedepot/categoryid/${nValue}/${formattedLabel}`,
      id: 'alternate'
    }));
  }
  if (isContentPageOnly) {
    const breadcrumbStructuredData = getBreadcrumbStructuredData({
      canonical,
      breadcrumbs: processedBreadcrumbs,
      title
    });
    tags.push(new ScriptTag({ content: breadcrumbStructuredData, id: 'breadcrumbStructuredData' }));
  } else {
    const browseSearchStructuredData = getBrowseSearchStructuredData({
      canonical,
      skus: products,
      title,
      breadcrumbs: processedBreadcrumbs
    });
    tags.push(new ScriptTag({ content: browseSearchStructuredData, id: 'browseSearchStructuredData' }));
  }
  return tags;
};

const CategoryMetadata = (props) => {
  const {
    keyword,
    navParam,
    pathWithQueryParams,
    variables: propVariables,
    seoGeneratedContentVariables,
  } = props;
  const storeId = useStoreId();

  let data;
  let error;
  let loading;

  const variables = propVariables || {
    keyword,
    navParam,
    storeId,
  };
  // eslint-disable-next-line
  const isControlledData = typeof props.data !== 'undefined' || typeof props.loading !== 'undefined';
  const skipSearch = isControlledData || (!keyword && !navParam && !propVariables);
  const searchOptions = {
    variables,
    skip: skipSearch,
  };
  ({ data, loading, error } = useDataModel('searchModel', searchOptions));
  let nval;
  const seoNavParam = seoGeneratedContentVariables?.navParam;
  if (seoNavParam) {
    nval = (seoNavParam.indexOf('N-') < 0) ? `N-${seoNavParam}` : seoNavParam;
  }
  const response = useDataModel('emtGeneratedContent', {
    variables: {
      nvalue: nval
    },
    skip: !nval
  });
  if (isControlledData) {
    ({ data, loading, error } = props);
  }
  const helmetData = {
    ...data,
    ...response.data
  };

  useHelmet('browseAndSearch',
    { data: helmetData, props: { ...props } },
    browseHandler,
    [keyword, navParam, data?.searchModel, response?.data?.emtGeneratedContent]);
  return null;
};

CategoryMetadata.displayName = 'CategoryMetadata';

CategoryMetadata.dataModel = extend({
  searchModel: params({
    keyword: stringType(),
    storefilter: customType('StoreFilter').enum(['ALL', 'IN_STORE', 'ONLINE'], 'ALL'),
    navParam: stringType()
  }).shape({
    metadata: shapeType({
      canonicalUrl: stringType()
    }),
    products: params().arrayOf({
      itemId: stringType(),
      dataSources: stringType(),
      identifiers: shapeType({
        brandName: stringType(),
        itemId: stringType(),
        productLabel: stringType()
      }),
      media: shapeType({
        images: arrayType({
          url: stringType()
        })
      }),
      pricing: params({ storeId: stringType() }).shape({
        value: numberType({ float: true })
      }),
      reviews: shapeType({
        ratingsReviews: shapeType({
          averageRating: stringType(),
          totalReviews: stringType()
        })
      })
    }),
    searchReport: shapeType({
      keyword: stringType()
    }),
    taxonomy: shapeType({
      brandLinkUrl: stringType(),
      breadCrumbs: arrayType(shapeType({
        browseUrl: stringType(),
        creativeIconUrl: stringType(),
        deselectUrl: stringType(),
        dimensionId: stringType(),
        dimensionName: stringType(),
        label: stringType(),
        refinementKey: stringType(),
        url: stringType()
      }))
    }),
    templates: arrayType(stringType())
  })
}, EmtGeneratedContent);

const VariablesObject = shape({
  navParam: string
});

CategoryMetadata.propTypes = {
  keyword: string,
  seoGeneratedContentVariables: oneOfType([
    string,
    object,
    instanceOf(VariablesObject)
  ]),
  navParam: string,
  pathWithQueryParams: string.isRequired,
  data: shape({}),
  loading: bool,
  variables: shape({})
};

CategoryMetadata.defaultProps = {
  keyword: null,
  navParam: null,
  data: undefined,
  loading: undefined,
  seoGeneratedContentVariables: null,
  variables: null
};

export { CategoryMetadata };
