import React, { memo, useContext } from 'react';
import { string } from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { AppContext } from '@thd-nucleus/app-render';
import { useLocation, useParams } from '@thd-olt-component-react/router';
import { useHelmet } from '@thd-nucleus/thd-helmet';
import { NoIndexNoFollowMetadata, Metadata } from '@thd-olt-component-react/metadata';

import { Redirector } from './Redirector';
import { getPageType } from '../utils/getPageType';
import { dataModel } from '../../contentful/dataModel/dataModel';
import { imagePreloader } from '../../contentful/utils/utilities';

export const LandingContainer = memo(({ ...props }) => {
  const { instance } = useContext(AppContext);
  let customerType = instance?.customer?.type;

  const { search: querystring, pathname } = useLocation();
  const pathWithQueryParams = `${pathname}${querystring}`;
  const isPreviewLink = !!instance?.headers?.['x-content-preview']
    || pathWithQueryParams.indexOf('render*') > -1
    || pathWithQueryParams.indexOf('contentPV') > -1
    || pathWithQueryParams.indexOf('bassPV') > -1;

  const { slug, guid } = useParams();

  let pageId = slug;
  if (guid) {
    pageId = guid;
  }

  // This is specific for being able to render pages for Contentful's previews because the page IDs in Contentful will
  // consist of n-value and the '-b2c'/'-b2b' suffix, which are used in the preview url instead of just the n-value
  // like in a production url.
  if (isPreviewLink && (pageId.endsWith('-b2c') || pageId.endsWith('-b2b'))) {
    customerType = pageId.slice(-3);
    pageId = pageId.replace(/-b2[b|c]$/, '');
  }

  const { data, loading, error } = useDataModel('layouts', {
    variables: {
      slug: pageId,
      customerType,
    },
  });

  useHelmet('landing-page', { data }, imagePreloader, [data]);

  if (!data || loading || error) {
    return null;
  }

  const layouts = data?.layouts;
  const PageType = getPageType(layouts);
  const { thdSEOBotDetection } = instance;

  return (
    <>
      {isPreviewLink && (
        <Metadata>
          <NoIndexNoFollowMetadata />
        </Metadata>
      )}
      <Redirector customerType={customerType} layoutType={layouts.type} pageId={pageId} />
      <PageType
        customerType={customerType}
        data={data}
        pageId={pageId}
        storeId={props?.storeId}
        pathWithQueryParams={pathWithQueryParams}
        thdSEOBotDetection={thdSEOBotDetection}
        isPreviewLink={isPreviewLink}
      />
    </>
  );
});

LandingContainer.dataModel = dataModel;

LandingContainer.displayName = 'LandingContainer';

LandingContainer.defaultProps = {};

LandingContainer.propTypes = {
  storeId: string.isRequired,
};
