import React from 'react';
import { EducationalLayout } from '../../contentful/templates/EducationalLayout';
import { NavigationalLayout } from '../../contentful/templates/NavigationalLayout';
import { InspirationalLayout } from '../../contentful/templates/InspirationalLayout';
import { FlexibleLayout } from '../../contentful/templates/FlexibleLayout';

export const getLayoutTemplate = (layouts) => {
  let pageTemplate = layouts?.content?.__typename;
  const layoutType = layouts?.content?.layoutType;
  pageTemplate = layoutType || pageTemplate;

  switch (pageTemplate) {
  case 'EducationalLayout':
    return EducationalLayout;
  case 'NavigationalLayout':
    return NavigationalLayout;
  case 'InspirationalLayout':
    return InspirationalLayout;
  case 'UniversalLayout':
    return FlexibleLayout;
  case 'Flexible':
    return FlexibleLayout;
  default:
    return () => React.Fragment;
  }
};