const getFAQMainEntity = (faq) => {
  try {
    return JSON.parse(JSON.parse(faq).Schema).mainEntity;
  } catch {
    return [];
  }
};

const getBreadcrumbsList = (breadcrumbs = []) => {
  if (breadcrumbs.length === 0) return {};

  if (breadcrumbs && breadcrumbs.length && breadcrumbs?.[0]?.label !== 'Home') {
    breadcrumbs.unshift({ label: 'Home', url: '' });
  }

  return {
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map(({ label, url }, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      item: {
        '@type': 'Thing',
        name: label,
        '@id': `https://www.homedepot.com${url}`,
      },
    })),
  };
};

export const getBrowseSearchStructuredData = ({ canonical, skus, title, breadcrumbs }) => {
  return {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: title,
    url: canonical,
    breadcrumb: getBreadcrumbsList(breadcrumbs),
    mainEntity: {
      '@type': 'WebPageElement',
      offers: {
        '@type': 'Offer',
        itemOffered: (skus || [])
          .map((sku) => (sku.toJSON ? sku.toJSON() : sku))
          .map(({
            identifiers = {},
            reviews = {},
            pricing = {},
            media = {}
          }) => ({
            '@type': 'Product',
            ...(Number(reviews?.ratingsReviews?.totalReviews) && {
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: reviews?.ratingsReviews?.averageRating,
                reviewCount: reviews?.ratingsReviews?.totalReviews,
                bestRating: '5',
                worstRating: '1',
              },
            }),
            brand: {
              '@type': 'Thing',
              name: identifiers.brandName,
            },
            description: identifiers.productLabel,
            image: media.images?.[0]?.url?.replace('<SIZE>', '100'),
            name: identifiers.productLabel,
            offers: {
              '@type': 'Offer',
              availability: 'http://schema.org/InStock',
              price: pricing?.value,
              priceCurrency: 'USD',
              url: `https://www.homedepot.com${identifiers.canonicalUrl}`,
            },
            sku: identifiers.itemId
          })),
      },
    },
  };
};

export const getBrowseSearchFAQStructuredData = ({ canonical, title, breadcrumbs, faq }) => {
  return {
    '@context': 'http://schema.org',
    '@type': 'FAQPage',
    name: title,
    url: canonical,
    breadcrumb: getBreadcrumbsList(breadcrumbs),
    mainEntity: getFAQMainEntity(faq),
  };
};

export const getEmtStructuredData = ({ faqs, schema, breadcrumbs }) => {
  const isSchemaAnArray = Array.isArray(schema);
  const arraySchema = isSchemaAnArray ? schema : [schema];
  const newSchemas = [];

  const isFAQSchema = arraySchema.some((schemaEl) => schemaEl['@type'] === 'FAQPage');

  if (!isFAQSchema) {
    newSchemas.push({
      '@context': 'http://schema.org',
      '@type': 'FAQPage',
      mainEntity: getFAQMainEntity(faqs)
    });
  }

  newSchemas.push({
    '@context': 'http://schema.org',
    ...getBreadcrumbsList(breadcrumbs),
  });

  return [...arraySchema, ...newSchemas];

};