import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography, Pill, Card } from '@one-thd/sui-atomic-components';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import './related-categories.scss';
import { track } from './related-categories-analytics';

export function RelatedCategories({ categories, noOfItems, nopadding }) {
  const { channel } = useContext(ExperienceContext);

  const validCategories = categories.filter(
    (category) => category?.pageTitle && category?.canonicalUrl
  ).slice(0, noOfItems);

  if (!validCategories.length) return null;

  return (
    <Card
      className="sui-h-full sui-leading-none sui-border-none sui-bg-transparent"
      data-component="RelatedCategories"
      disablePadding={nopadding}
    >
      <Typography variant="h2" weight="bold">Related Categories</Typography>
      <div className="related-categories sui-flex sui-flex-row sui-flex-wrap sui-leading-tight sui-gap-4">
        {validCategories.map(
          ({ categoryId, pageTitle, canonicalUrl }) => {
            return (
              <Pill
                label={pageTitle}
                key={categoryId}
                component="a"
                href={canonicalUrl}
                onClick={() => track(pageTitle)}
                clickable
              />
            );
          }
        )}
      </div>
    </Card>
  );
}

RelatedCategories.defaultProps = {
  categories: [],
  noOfItems: 10,
  nopadding: false
};

const Categories = PropTypes.shape({
  categoryId: PropTypes.string,
  pageTitle: PropTypes.string,
  canonicalUrl: PropTypes.string
});

RelatedCategories.propTypes = {
  categories: PropTypes.arrayOf(Categories),
  noOfItems: PropTypes.number,
  nopadding: PropTypes.bool
};