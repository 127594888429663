/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  params,
  useDataModel,
  arrayOf,
  string,
  shape
} from '@thd-nucleus/data-sources';
import { Heading } from '@thd-olt-component-react/core-ui';
import { ProductCategoryContent } from './ProductCategoryContent';
import { MarketingQuestionAnswer } from './MarketingQuestionAnswer';
import { RelatedCategories } from './subcomponents/related-categories/RelatedCategories';

export const EmtGeneratedContent = (props) => {
  const { nvalue, type, nopadding } = props;
  let nval = (nvalue?.indexOf('N-') < 0) ? `N-${nvalue}` : nvalue;
  const { loading, data, error } = useDataModel('emtGeneratedContent', { variables: { nvalue: nval }, skip: !nval });
  if ((error && !data) || loading || !data?.emtGeneratedContent) { return null; }
  let questionAndAnswers = [];
  switch (type) {
  case 'productCategoryContent':
    return <ProductCategoryContent content={data?.emtGeneratedContent?.productCategoryContent} />;
  case 'questionsAndAnswers':
    try {
      let jsonContent = JSON.parse(data?.emtGeneratedContent?.questionAndAnswer);
      questionAndAnswers = jsonContent.Questions;
    } catch (err) {
      return null;
    }
    if (!questionAndAnswers || questionAndAnswers.length === 0) { return null; }
    return (
      <div data-component="EmtGeneratedContent">
        <Heading title="Questions & Answers" underline />
        <MarketingQuestionAnswer qaSets={questionAndAnswers} />
      </div>
    );
  case 'relatedCategories':
    return (
      <RelatedCategories categories={data?.emtGeneratedContent?.relatedCategoryContent?.relatedCategories} nopadding={nopadding} />
    );
  default:
    return null;
  }

};
EmtGeneratedContent.displayName = 'EmtGeneratedContent';

EmtGeneratedContent.defaultProps = {
  type: 'questionsAndAnswers',
  nvalue: '',
  nopadding: false
};

EmtGeneratedContent.propTypes = {
  nvalue: PropTypes.string,
  type: PropTypes.string,
  nopadding: PropTypes.bool
};

const paramsForQuery = params({ nvalue: string().isRequired() });
EmtGeneratedContent.dataModel = {
  emtGeneratedContent: paramsForQuery.shape({
    questionAndAnswer: string(),
    productCategoryContent: shape({
      title: string(),
      data: string()
    }),
    relatedCategoryContent: shape({
      relatedCategories: arrayOf(
        shape({
          categoryId: string(),
          pageTitle: string(),
          canonicalUrl: string()
        })
      )
    })
  })
};
