import { CategoryPage } from '../types/CategoryPage';
import { ContentPage } from '../types/ContentPage';

export const getPageType = (layouts) => {
  const pageType = layouts?.type;

  switch (pageType) {
  case 'Category':
    return CategoryPage;
  default:
    return ContentPage;
  }
};